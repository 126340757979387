<template>
  <div class="auth-button-component">
    <button
      class="btn"
      :class="{ alternative: alternative }"
      :disabled="disabled"
      @click="$emit('click')"
    >
      <div class="button-background" />
      <loading :show="loading">
        <span>{{ text }}</span>
      </loading>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true
    },
    alternative: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.auth-button-component {
  ::v-deep .btn {
    width: 100%;
    height: 58px;
    font-weight: 500;
    box-shadow: none;
    color: white;
    background: transparent;
    padding-block: 14px;
    font-size: 18px;
    transition: opacity 0.2s;
    span {
      z-index: 20;
    }
    &.disabled,
    &:disabled {
      opacity: 0.4;
    }
    &::before {
      content: "";
      top: 0px;
      left: 0px;
      height: 56px;
      width: 100%;
      z-index: 100;
      border-radius: 3px;
      position: absolute;
      transition: 0.2s opacity;

      background: repeating-linear-gradient(180deg, #cca2fb, #cca2fb),
        linear-gradient(180deg, #e8dafb, #b187e2),
        linear-gradient(
          90deg,
          #cca2fb 4%,
          #9c50eb 20%,
          #6b2fb1 30%,
          #6b2fb1 45%,
          #8b56c9 65%,
          #e8dafb 95%
        ),
        linear-gradient(
          90deg,
          #cca2fb 13%,
          #923fea 25%,
          #6b2fb1 55%,
          #6b2fb1 75%,
          #b187e2 90%
        );
      background-size: 2px 100%, 2px 100%, 100% 2px, 100% 2px;
      background-position: 0 0, 100% 0, 0 0, 0 100%;
      background-repeat: no-repeat;
    }
    &:not(:disabled) {
      &:hover {
        .button-background {
          opacity: 0.64;
        }
      }
      &:active {
        .button-background {
          opacity: 0.8;
        }
      }
    }

    .button-background {
      z-index: -10;
      inset: 0;
      opacity: 0.24;
      position: absolute;
      transition: 0.2s all;
      border-radius: 4px;
      background: linear-gradient(
          0deg,
          rgba(48, 0, 110) 0%,
          rgba(48, 0, 110) 100%
        ),
        radial-gradient(
          49.13% 240.28% at 50% 50%,
          rgba(48, 0, 110, 0.15) 0%,
          rgba(48, 0, 110, 0.04) 100%
        );
    }

    &.alternative {
      font-size: 20px;
      height: 52px;
      span {
        z-index: 2;
      }
      .button-background {
        border-radius: 2px;
        background: linear-gradient(
            0deg,
            rgba(48, 0, 110) 0%,
            rgba(48, 0, 110) 100%
          ),
          radial-gradient(
            49.13% 240.28% at 50% 50%,
            rgba(48, 0, 110, 0.15) 0%,
            rgba(48, 0, 110, 0.04) 100%
          );
        position: absolute;
        inset: 0;
        opacity: 0;
        transition: 0.2s all;
      }
      &:hover {
        .button-background {
          opacity: 0.32;
        }

        box-shadow: 0px 0px 40px 0px rgba(112, 0, 255, 0.16),
          0px 0px 40px 0px rgba(128, 43, 255, 0.16) inset;
      }
      &:active {
        .button-background {
          opacity: 0.64;
        }

        box-shadow: 0px 0px 40px 0px rgba(112, 0, 255, 0.16),
          0px 0px 40px 0px rgba(128, 43, 255, 0.16) inset;
      }
      &::before {
        display: none;
      }
      &.new-account {
        font-size: 20px;
        span {
          z-index: 2;
        }
        .button-background {
          border-radius: 2px;
          background: linear-gradient(
              0deg,
              rgba(48, 0, 110) 0%,
              rgba(48, 0, 110) 100%
            ),
            radial-gradient(
              49.13% 240.28% at 50% 50%,
              rgba(48, 0, 110, 0.15) 0%,
              rgba(48, 0, 110, 0.04) 100%
            );
          position: absolute;
          inset: 0;
          opacity: 0;
          transition: 0.2s all;
        }
        &:hover {
          .button-background {
            opacity: 0.16;
          }

          box-shadow: 0px 0px 40px 0px rgba(112, 0, 255, 0.16),
            0px 0px 40px 0px rgba(128, 43, 255, 0.16) inset;
        }
        &:active {
          .button-background {
            opacity: 0.64;
          }

          box-shadow: 0px 0px 40px 0px rgba(112, 0, 255, 0.16),
            0px 0px 40px 0px rgba(128, 43, 255, 0.16) inset;
        }
        &::before {
          display: none;
        }
      }
    }
  }
}
</style>
