<template>
  <div class="view-forgot-password mt-3">
    <div v-if="!sent">
      <p class="mb-3">
        Informe o e-mail cadastrado para recuperar sua senha
      </p>
      <form>
        <form-group
          v-model="form.email"
          id="email"
          label="E-mail"
          icon="fa-regular fa-envelope"
          type="email"
          placeholder="Digite seu e-mail"
          :errors="errors.email"
          autoCheck
          @setError="setError"
        />

        <auth-button
          text="Recuperar senha"
          class="mt-4 pt-3"
          :loading="loading"
          :disabled="loading"
          @click="submit"
        />
      </form>
      <p class="text-center text-white mt-4">
        <router-link class="main" :to="{ name: 'login' }">
          Voltar para login
        </router-link>
      </p>
    </div>
    <div v-else>
      <div class="d-flex justify-content-center">
        <lottie-vue-player
          :src="check"
          class="mr-4"
          autoplay
          :speed="0.8"
          :player-controls="false"
          style="width: 136px; background: transparent;"
        />
      </div>
      <p class="text-center mb-4">
        Concluído!
      </p>
      <p class="text-center fs-16 mb-5">
        Verifique seu e-mail e siga as instruções de recuperação da senha.
      </p>
      <auth-button text="Fazer login" class="mt-2" @click="login" />
    </div>
  </div>
</template>

<script>
import AuthButton from "@/components/auth/AuthButton.vue";
import { formFields } from "@/functions/helpers.js";

export default {
  components: { AuthButton },
  data() {
    return {
      ...formFields(["email"]),
      sent: false,
      loading: false
    };
  },
  computed: {
    check() {
      return JSON.stringify(require("@/assets/auth/check.json"));
    }
  },
  methods: {
    setError(error, field) {
      this.errors[field] = error;
    },
    login() {
      this.$router.push({ name: "login" });
    },
    next() {
      this.choseSegment = true;
    },
    submit() {
      this.loading = true;

      this.$store
        .dispatch("user/forgot", {
          email: this.form.email
        })
        .then(() => {
          this.sent = true;
        })
        .catch(error => {
          if (error.response.data.message) {
            this.$message.error(error.response.data.message[0]);
          } else this.errors = error.response.data;

          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    _submit() {
      this.$store
        .dispatch("user/forgot", {
          email: this.form.email
        })
        .then(() => {
          this.sent = true;
        })
        .catch(error => {
          if (error.response.data.message) {
            this.$message.error(error.response.data.message[0]);
          } else this.errors = error.response.data;

          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-forgot-password {
  p {
    color: var(--std-neut-x-700, #d5d5d5) !important;
    margin: 0;
  }
  a {
    font-size: 16px;
  }
}
</style>
